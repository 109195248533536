import * as React from 'react'
import Layout from '../../layout'
import LeaderboardsPage from '../../pagesComponents/leaderboards'

const Leaderboards = () => {
	return (
		<Layout title="Leaderboards">
			<LeaderboardsPage />
		</Layout>
	)
}

export default Leaderboards
